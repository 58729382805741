import * as d3 from 'd3';
function makeCircle(ctr, x, y, r) {
  return ctr.append('circle').attr('cx', x).attr('cy', y).attr('r', r);
}
function makeText(ctr, x, y, t) {
  return ctr.append('text').attr('x', x).attr('y', y).text(t);
}
function randomPosNeg() {
  let base = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 20;
  return (Math.random() < 0.5 ? -1 : 1) * (Math.random() * base);
}
export function makeBee(ctr, w, h) {
  var svg = ctr.append('svg').attr('width', w).attr('height', h).attr('class', 'animal-svg');
  let pointX = 0;
  let pointY = h / 2 + Math.random() * 20;
  var templatePathPoints = [];
  function addPoint() {
    templatePathPoints.push([pointX, pointY]);
  }
  function add(dx, dy) {
    let pushPoint = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    pointX += dx;
    pointY += dy;
    if (pushPoint) addPoint();
  }
  add(w / 8, h / 5);
  add(w / 8, -h / 5);
  add(w / 8, -h / 5);
  add(w / 8, h / 5);
  add(w / 8, h / 2);
  add(w / 8, h / 10);
  add(w / 8, -h / 10);
  add(w / 2, h / 10);
  const templatePath = svg.append('path').attr('class', 'template-path').attr('d', d3.line().curve(d3.curveBundle)(templatePathPoints));

  // place a bee
  var imgW = 58;
  var imgH = 53;
  var flyingImgW = 72;
  var flyingImgH = 68;
  let [initX, initY] = templatePathPoints[0];
  const mainBee = svg.append('image').attr('width', imgW).attr('height', imgH).attr('href', 'img/bee.png').attr('transform', `translate(${initX}, ${initY})`);
  const hideFlyingBee = svg.append('image').attr('width', imgW).attr('height', imgH).attr('href', 'img/flying-bee.png').style('opacity', 0);
  var curve = d3.line().curve(d3.curveBundle)(templatePathPoints);
  let flyingBee, flyingSvg;
  var v = 0.08;
  var a = 0.02;
  let d = 0;
  let c = 0;
  let an = 0;
  var keyPoints = [];
  var initSlow = 2;
  while (d < 1) {
    c++;
    keyPoints.push(+d.toFixed(2));
    if (d > 1) d = 1;
    if ((c % 2 === 0 || an > 0) && c > initSlow) {
      if (an < 2) {
        v += a;
      }
      if (an > 2) {
        v -= a;
      }
      // console.log(c, v, an);
      an++;
      if (an > 4) {
        an = 0;
      }
    }
    d += v;
  }
  var keyTimes = keyPoints.map((d, i) => +(i / keyPoints.length).toFixed(2));

  // console.log(keyPoints, keyTimes);

  keyPoints = [0, 0.08, 0.16, 0.24, 0.34, 0.46, 0.58, 0.68, 0.76, 0.84, 0.94, 1];
  keyTimes = [0, 0.07, 0.14, 0.24, 0.36, 0.45, 0.55, 0.64, 0.73, 0.82, 0.91, 1];
  // console.log(le)

  // plot circles at the above distances on the pathh
  let l = templatePath.node().getTotalLength();
  keyPoints.forEach(d => {
    let {
      x,
      y
    } = templatePath.node().getPointAtLength(d * l);
    makeCircle(svg, x, y, 2);
    makeText(svg, x, y, d);
  });
  function addPath() {
    flyingSvg = ctr.append('svg').attr('width', w).attr('height', h).attr('class', 'flying-svg');

    // sigh. add a square on top of the svg because the beee jumps at the start of the animation..
    // if i prre-translate the bee, thee animateMotion loop seems to scale by some amount. weird.

    flyingBee = flyingSvg.append('image').attr('width', flyingImgW).attr('height', flyingImgH).attr('href', 'img/flying-bee.png');
    flyingSvg.append('rect').attr('width', flyingImgW + 2).attr('height', flyingImgH + 2).attr('class', 'hide-bee-rect');
    mainBee.style('opacity', 0);
    var anim = flyingBee.append('animateMotion').attr('dur', '4s').attr('restart', 'whenNotActive').attr('begin', 'indefinite').attr('keyTimes', keyTimes.join(';')).attr('keyPoints', keyPoints.join(';')).attr('calcMode', 'linear').attr('path', curve).attr('rotate', 'auto').node();
    return anim;
  }
  function hide() {
    mainBee.style('opacity', 0);
    flyingBee.style('opacity', 0);
  }
  function removePath() {
    mainBee.style('opacity', 1);
    if (flyingSvg) flyingSvg.remove();
  }
  return {
    addPath,
    removePath,
    hide
  };
}