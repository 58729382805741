import './array-from-polyfill';
import '../scss/main.scss';
import scrolly from './scrolly';
import * as d3 from 'd3';
import { makeBee } from './bees';
// import scrollama from 'scrollama';
import enterViewMin from './enter-view';
import { makeAnts } from './ants';

// IF we're in an iframe...
const isEmbedded = window.location !== window.parent.location;
// You can write code specific to embeds like this...
if (isEmbedded) {
  try {
    window.childFrame.sendHeight();
  } catch (err) {
    console.log('pym not found.');
  }
}

// Get the current locale: en, es, de, etc.
// const { lang } = document.documentElement;
// // ... use it to import text or data ...
// import(`Locales/${lang}/article/text.md`).then((text) => {
//   console.log(text.intro);
// });

// const height = document.querySelector('#ai2html-background').offsetHeight;
// document.getElementById('parent').style.height = height + 'px';
// console.log(height);

window.addEventListener('load', function () {
  // console.log('All assets are loaded');
  scrolly('#scrolly-intro');
});
const antCtr = d3.select('#ants');
const beeCtr = window.innerWidth < 500 ? d3.select('#bee-phone') : d3.select('#bee');
var w = (window.innerWidth * 0.7 > 500 ? window.innerWidth * 0.7 : 500) / Math.cos(30 * Math.PI / 180);
var h = 300;
const bee = makeBee(beeCtr, w, h);
const ant = makeAnts(antCtr, 300, 500, 20);
let anim;

// const scrollerBee = scrollama();
// scrollerBee.setup({
//   step: document.querySelector('#bee svg image'),
//   offset: 0.6,
// });

// scrollerBee.onStepEnter(() => {
//   console.log("entered svg");
//   if(!added) {
//     anim = bee.addPath();
//     added = true;
//   }
//   anim.beginElement();
// });

// const scrollerByline = scrollama();
// scrollerByline.setup({
//   step: '#byline',
//   offset: 0,
// });

window.added = false;
window.begun = false;
window.ended = false;
window.resetQueue = false;

// scrollerByline.onStepEnter(bee.removePath);
enterViewMin({
  selector: window.innerWidth < 500 ? '#bee-phone svg image' : '#bee svg image',
  offset: window.innerWidth < 500 ? 0.4 : 0.4,
  enter: () => {
    if (!added) {
      anim = bee.addPath();
      added = true;
      anim.beginElement();
      anim.setAttributeNS(null, 'onbegin', 'begun=true;ended=false;');
      anim.setAttributeNS(null, 'onend', 'begun=false;ended=true;if(resetQueue){resetBee(); resetQueue = false;}');
    }
  }
});

// window.hideBee = function() {
//   bee.hide();
// }

window.resetBee = function () {
  bee.removePath();
  added = false;
  resetQueue = false;
};

// function queueBegin() {
//   anim.beginElement();
// }

enterViewMin({
  selector: window.innerWidth < 500 ? '.first-paragraph' : '#byline',
  offset: 1,
  enter: () => {
    if (ended) {
      window.resetBee();
      // ended = false;
    } else {
      window.resetQueue = true;
    }
  }
  // exit: () => {
  //   if(!begun && !ended && !added) {
  //     bee.addPath();
  //   }
  // }
});