import * as d3 from 'd3';
const numPathPoints = 1000;
let totalLength = null;
let templatePath = null;
let svg;
function findRot(currPoint, nextPoint) {
  var ang = Math.atan((nextPoint.y - currPoint.y) / (nextPoint.x - currPoint.x)) * 180 / Math.PI;
  // prevent goes from 90 to -90.
  // because tan hits infinity
  if (ang < 0) ang += 180;
  return ang;
}
function randomPosNeg() {
  let base = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 20;
  return (Math.random() < 0.5 ? -1 : 1) * (Math.random() * base);
}
function jitterPathAndAddRotation(svg, w, h) {
  let reverse = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let numTurns = 10;
  let currPoint = null;
  // split width randomly into numTurns points
  let pointX = w + w / 10;
  let pointY = h;
  const pathPoints = [];
  pathPoints.push([pointX, pointY]);
  const yJump = h / numTurns;
  while (numTurns--) {
    pointY -= yJump + Math.random() * 5;
    pointX = w / 2 + w / 2 * Math.pow((pointY - h / 2) / (h / 2), 2) + randomPosNeg(20);
    pathPoints.push([pointX, pointY]);
  }
  templatePath = svg.append('path').attr('class', 'template-path').attr('d', d3.line().curve(d3.curveBasis)(pathPoints));
  totalLength = templatePath.node().getTotalLength();
  let prevRot = null;
  return d3.range(numPathPoints).map(d => {
    if (currPoint === null) {
      currPoint = templatePath.node().getPointAtLength(d * totalLength / numPathPoints);
    }
    let nextPoint = templatePath.node().getPointAtLength((d + 1) * totalLength / numPathPoints);
    let rot = findRot(currPoint, nextPoint) + (reverse ? 0 : 180);

    // if(prevRot < 0 && rot > 0) {
    // }
    currPoint = nextPoint;
    prevRot = rot;
    return {
      x: currPoint.x,
      y: currPoint.y,
      rot
    };
  });
}
export function makeAnts(ctr, w, h) {
  let nAnts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 20;
  const start = window.performance.now();
  svg = ctr.append('svg').attr('width', w).attr('height', h).attr('class', 'animal-svg');
  const scale = 0.7;
  var imgW = 20 * scale;
  var imgH = 19 * scale;
  const ants = [];
  d3.range(nAnts).forEach((d, i) => {
    const ant = svg.append('image').attr('width', imgW).attr('height', imgH).attr('href', 'img/ant.png');

    // place ant randomly on path
    ant.reverse = Math.random() > 0.9;
    ant.path = jitterPathAndAddRotation(svg, w, h, ant.reverse);
    ant.path.id = i;
    ant.currentIndex = Math.floor(Math.random() * ant.path.length);
    const antLoc = ant.path[ant.currentIndex];
    ant.attr('x', antLoc.x);
    ant.attr('y', antLoc.y);
    ant.attr('transform', `rotate(${antLoc.rot} ${antLoc.x} ${antLoc.y})`);
    ant.tick = function () {
      const skip = Math.random() < 0.5 ? 1 : 0;
      const jump = (this.reverse ? -1 : 1) * (1 + skip ? Math.floor(Math.random() * 10) : 0);
      this.currentIndex = (this.currentIndex + jump) % this.path.length;
      if (this.currentIndex < 0) {
        this.currentIndex = this.path.length + this.currentIndex;
      }

      // randomly reverse
      const antLoc = this.path[this.currentIndex];
      ant.attr('x', antLoc.x);
      ant.attr('y', antLoc.y);
      ant.attr('transform', `rotate(${antLoc.rot} ${antLoc.x} ${antLoc.y})`);
    };
    ants.push(ant);
  });
  const end = window.performance.now();
  setInterval(() => {
    ants.forEach(d => d.tick());
  }, 60);
}