import debounce from 'lodash.debounce';
import enterView from 'enter-view';
import styles from './_scrolly.scss';
import { slice } from 'lodash';
const scrolly = id => {
  const scrollerEl = id;
  document.querySelector(id).classList.add(styles.component);
  setSize(scrollerEl);
  window.addEventListener('resize', debounce(() => {
    setSize(scrollerEl);
  }, 250));

  /*
      ------------------------------------------
      Do things here as scroll-sections progress
      ------------------------------------------
    */

  function changeActiveStep(el) {
    let exit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    activeStep = +el.dataset.step;
    if (exit) {
      activeStep--;

      // jic...
      if (activeStep < 0) {
        activeStep = 0;
      }
    }
    Array.from(document.querySelectorAll('.slide')).forEach(slide => {
      if (+slide.dataset.step <= activeStep) {
        slide.classList.add('active');
      } else {
        slide.classList.remove('active');
      }
    });
  }
  let activeStep = 0;
  enterView({
    selector: `${scrollerEl} .anno-block`,
    enter: el => changeActiveStep(el, false),
    exit: el => changeActiveStep(el, true),
    offset: 0.1
  });
};
const setSize = scrollerEl => {
  // set widths
  // const { width } = document.querySelector(scrollerEl).getBoundingClientRect();
  // console.log(width);
  // document.querySelectorAll(`${scrollerEl} .slide`).forEach((slide) => {
  //   slide.style.width = `${width}px`;
  // });
  // Added by Manas
  // center within viewport
  // adjustnig the height wasn't workiing
  // so adjust the width to maintain aspect ratio till it hits the desired height
  var scrollyHeight = Array.from(document.querySelectorAll('#scrolly-intro .g-artboard')).filter(d => d.style.display !== 'none')[0].clientHeight;
  var scrollyWidth = Array.from(document.querySelectorAll('#scrolly-intro .g-artboard')).filter(d => d.style.display !== 'none')[0].clientWidth;
  var aspect = scrollyWidth / scrollyHeight;
  var margin = 10;
  var targetHeight = window.innerHeight - margin * 2;
  var targetWidth = targetHeight * aspect <= document.documentElement.clientWidth ? targetHeight * aspect : document.documentElement.clientWidth;

  // console.log(scrollyHeight, scrollyWidth, targetHeight, targetWidth);
  Array.from(document.querySelectorAll('#scrolly-intro .g-artboard')).filter(d => d.style.display !== 'none').forEach(d => {
    if (d.id.match(/(md|sm|xs)/gi) !== null) return;
    d.style.width = targetWidth + 'px';
    // also center it on the screen
    // d.style.right =
    //   (targetWidth - document.documentElement.clientWidth) / 2 + 'px';
  });
};

export default scrolly;